import mock from 'src/utils/mock';
import type { Airdrop } from 'src/types/airdrop';

mock.onGet('/api/airdrops/list').reply(() => {
  const airdrops: Airdrop[] = [
    { 
      name: '𝜏Bitcoin',
      amount: 0.000000001,
      approx: 10.00,
      currency: '$'
    },
    { 
      name: '𝜏Ether',
      amount: 0.000000002,
      approx: 10.00,
      currency: '$'
    },
    { 
      name: '𝜏Doge',
      amount: 0.000000003,
      approx: 10.00,
      currency: '$'
    },
  ];

  return [200, {
    airdrops
  }]
});
