export interface Token {
  name: string;
  decimals: number;
  symbol: string;
  logoURI: string;
  address: string;
}

export enum EnTokenMining{
  DAILY,BLOCK
}

export interface StakingItem{
  stoken:Token;
  pid:number;
}

export interface FarmingInfo{
  address:string;
  type:EnTokenMining;
  staking:StakingItem[];
  rtoken:Token;
}

export interface PriceItem {
  max_supply: number;
  name: string;
  price: number;
  symbol: string;
  total_supply?: number;
  twap?: number;
}
