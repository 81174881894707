import { Market } from 'src/types/airdrop';

export enum ChainId {
  MAINNET = 56,
  BSCTESTNET = 97
}

export const NetworkContextName = 'NETWORK';

export const connectorLocalStorageKey = 'connectorId';

export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const MARKETS: Market[] = [
  {
    href: 'https://pancakeswap.finance/',
    name: 'PancakeSwap'
  }
];
