import React from 'react';

export default (props) => {
  return (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-89.000000, -416.000000)" fill="currentColor">
            <g transform="translate(99.000000, 426.000000) rotate(-90.000000) translate(-99.000000, -426.000000) translate(89.000000, 416.000000)">
                <rect x="1" y="0" width="2" height="16" rx="1"></rect>
                <rect x="6.66666667" y="0" width="2" height="20" rx="1"></rect>
                <rect x="12.3333333" y="0" width="2" height="20" rx="1"></rect>
                <rect x="18" y="0" width="2" height="20" rx="1"></rect>
            </g>
        </g>
    </g>
</svg>
)
}

