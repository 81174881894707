import React from 'react';

const StatsIcon = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>StatsIcon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-88.000000, -343.000000)" fill="currentColor">
          <g transform="translate(88.286246, 343.754101)">
            <rect x="0" y="12" width="2" height="8" rx="1"></rect>
            <rect x="6" y="8" width="2" height="12" rx="1"></rect>
            <rect x="12" y="4" width="2" height="16" rx="1"></rect>
            <rect x="18" y="0" width="2" height="20" rx="1"></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StatsIcon;
