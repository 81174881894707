import React from 'react';

const MineIcon = (props) => {
  return (
    <svg width="67px" height="69px" viewBox="0 0 67 69" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  {...props}>
      <title>MineIcon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-395.000000, -569.000000)" stroke="currentColor" strokeWidth="4">
          <g transform="translate(395.000000, 568.500000)">
            <path d="M43.4075903,35.6230832 L12.8943917,66.0485476 C12.5037037,66.4381123 11.8713237,66.4376571 11.4811969,66.0475303 L3.85261634,58.4189498 C3.46209204,58.0284255 3.46209204,57.3952605 3.85261634,57.0047362 L32.9488175,27.908535" id="形状"></path>
            <path d="M48.9225748,21.5078216 L31.4082647,3.68080193 C31.021211,3.2868376 30.3880709,3.28123541 29.9941065,3.66828908 C29.979136,3.68299704 29.9646321,3.69817263 29.9506165,3.7137933 L20.2794035,14.4925727 C19.9105705,14.9036452 19.9448124,15.5358836 20.3558849,15.9047167 C20.3719417,15.9191235 20.3884604,15.9330071 20.4054152,15.9463454 L39.8551542,31.2474757 L56.3029364,47.6952578 C56.6726324,48.0649539 57.2647103,48.0874667 57.6614126,47.7469118 L62.5299156,43.5674728 C62.9130219,43.2385895 62.9908616,42.6757118 62.7113881,42.2552012 L48.9225748,21.5078216 Z" id="矩形"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MineIcon;
