import React from 'react';

export default (props) => {
  return (
    <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-89.000000, -460.000000)" stroke="currentColor" strokeWidth="2">
            <g transform="translate(89.000000, 460.000000)">
                <path d="M10.5,12.0125049 C10.5,11.3437538 10.5,10.877427 10.5,10.6135243 C10.5,9.9874114 10.9076076,9.9874114 11.0997404,9.94010417 C12.1908008,9.67146154 13,8.68648249 13,7.51250486 C13,6.13179299 11.8807119,5.01250486 10.5,5.01250486 C9.11928813,5.01250486 8,6.13179299 8,7.51250486 M10.5,19 C15.4705627,19 19.5,14.9705627 19.5,10 C19.5,5.02943725 15.4705627,1 10.5,1 C5.52943725,1 1.5,5.02943725 1.5,10 C1.5,14.9705627 5.52943725,19 10.5,19 Z"></path>
                <path d="M10.5,13 L10.5,15.4416866" ></path>
            </g>
        </g>
    </g>
</svg>
  )
}
