import React from 'react';

export default (props) => {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>CommunityIcon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-88.000000, -500.000000)">
              <g transform="translate(88.000000, 500.500000)">
                  <path d="M1.77412457,19.3247719 L1.77412457,4.87302103 C1.77412457,3.21616678 3.11727032,1.87302103 4.77412457,1.87302103 L16.2258754,1.87302103 C17.8827297,1.87302103 19.2258754,3.21616678 19.2258754,4.87302103 L19.2258754,12.8526529 C19.2258754,14.5095072 17.8827297,15.8526529 16.2258754,15.8526529 L5.23979973,15.8526529" stroke="currentColor" strokeWidth="2"></path>
                  <circle fill="#FFFFFF" cx="6.13706229" cy="8.85372137" r="1"></circle>
                  <circle fill="#FFFFFF" cx="10.5" cy="8.85372137" r="1"></circle>
                  <circle fill="#FFFFFF" cx="14.8629377" cy="8.85372137" r="1"></circle>
              </g>
          </g>
      </g>
  </svg>
  )
}
