import React from 'react';

const MediumIcon = props => {
  return (
    <svg
      width="24px"
      height="15px"
      viewBox="0 0 24 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <polygon
          id="path-1"
          points="4.90649481e-05 0.0386969155 23.9051351 0.0386969155 23.9051351 12.9999436 4.90649481e-05 12.9999436"
        ></polygon>
        <polygon
          id="path-3"
          points="4.68258731e-05 0.0386904762 12.8549885 0.0386904762 12.8549885 13 4.68258731e-05 13"
        ></polygon>
        <polygon
          id="path-5"
          points="0.10048078 0.0658625 2.8903125 0.0658625 2.8903125 10.9848903 0.10048078 10.9848903"
        ></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-241.000000, -441.000000)">
          <g transform="translate(241.000000, 441.000000)">
            <g transform="translate(0.000000, 1.000000)">
              <mask id="mask-2" fill="currentColor">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                d="M22.8909568,0.995289286 C22.7639838,0.997765476 22.6370108,1.00024167 22.5100378,1.00271786 C22.3146324,1.43589643 22.0336054,1.85251548 21.9379297,2.30488452 C21.3450649,5.10638452 21.3423081,7.91902738 21.9384162,10.7200631 C22.0373351,11.1849679 22.3323081,11.6118012 22.5366324,12.0561226 C22.6520919,12.0530274 22.7675514,12.0500869 22.8830108,12.0471464 C23.0839297,11.5981821 23.3743622,11.1660869 23.4714973,10.6976226 C24.0468486,7.92088452 24.051227,5.13253929 23.4755514,2.35518214 C23.3777676,1.88362262 23.0905784,1.44765833 22.8909568,0.995289286 M14.1061459,6.37187262 C14.1149027,8.18165833 14.3939838,9.7685869 15.3155514,11.1848131 C16.5859297,13.1366702 18.4016595,13.1105155 19.6824162,11.1538607 C20.4757135,9.94176548 20.7706865,8.58574167 20.8537135,7.19350357 C20.9704703,5.23684881 20.7071189,3.32909881 19.5256054,1.66246786 C18.3163622,-0.0430083333 16.6276054,-0.015925 15.4300378,1.69388452 C14.4046865,3.15777738 14.1234973,4.816825 14.1061459,6.37187262 M6.76555135,12.999706 C10.5534973,12.9685988 13.4960919,10.065575 13.4694973,6.38595595 C13.4443622,2.8980869 10.4017135,0.032825 6.72971351,0.0387059524 C2.89701081,0.0448964286 -0.0403945946,2.94853929 0.00047027027,6.69052738 C0.0385783784,10.1822655 3.09192973,13.0298845 6.76555135,12.999706"
                id="Fill-1"
                fill="currentColor"
                mask="url(#mask-2)"
              ></path>
            </g>
            <g transform="translate(0.000000, 1.000000)">
              <mask id="mask-4" fill="currentColor">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g id="Clip-4"></g>
              <path
                d="M6.45680595,12.9997679 C2.95082976,13.0299464 0.0368178571,10.1823274 0.000448809524,6.69043452 C-0.0385511905,2.94844643 2.76480595,0.0449583333 6.42260357,0.0386130952 C9.92703214,0.0327321429 12.8308298,2.89814881 12.8548179,6.38601786 C12.8801988,10.0656369 10.0718893,12.9686607 6.45680595,12.9997679"
                id="Fill-3"
                fill="currentColor"
                mask="url(#mask-4)"
              ></path>
            </g>
            <path
              d="M14,7.37024454 C14.0179335,5.97261739 14.3085564,4.48123084 15.3683075,3.16539972 C16.6060526,1.62852456 18.3514659,1.6041805 19.6011107,3.13716061 C20.8224308,4.63522439 21.0946174,6.35015895 20.9739435,8.10877371 C20.888131,9.3601974 20.5832619,10.5790696 19.76335,11.6685705 C18.4396248,13.4273244 16.5629787,13.4508338 15.2499801,11.6963923 C14.297327,10.4234067 14.0090505,8.99698405 14,7.37024454"
              id="Fill-5"
              fill="currentColor"
            ></path>
            <g transform="translate(21.000000, 2.000000)">
              <mask id="mask-6" fill="currentColor">
                <use xlinkHref="#path-5"></use>
              </mask>
              <g id="Clip-8"></g>
              <path
                d="M1.71766875,0.0658625 C1.94848125,0.512431944 2.28054375,0.942806944 2.39360625,1.40832083 C3.05923125,4.15007083 3.05416875,6.90266806 2.38891875,9.64380694 C2.27660625,10.1062653 1.94079375,10.5328208 1.70848125,10.9760292 C1.57498125,10.9789319 1.44148125,10.9818347 1.30798125,10.9848903 C1.07173125,10.5462653 0.73066875,10.1249042 0.61629375,9.66595972 C-0.07295625,6.90083472 -0.06976875,4.12425139 0.61573125,1.35866806 C0.72635625,0.912098611 1.05129375,0.500820833 1.27723125,0.0731958333 C1.42404375,0.0707513889 1.57085625,0.0683069444 1.71766875,0.0658625"
                id="Fill-7"
                fill="currentColor"
                mask="url(#mask-6)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MediumIcon;
