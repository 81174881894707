import { Token, EnTokenMining, FarmingInfo } from 'src/types/token';
import { contractConfig } from 'src/config';
import BTCBLogo from 'src/assets/images/BTCB@2x.png';
import TBTCLogo from 'src/assets/images/TBTC@2x.png';
import BTCSTLogo from 'src/assets/images/BTCST@2x.png';
import TDOGELogo from 'src/assets/images/TDOGE@2x.png';
import DOGELogo from 'src/assets/images/DOGE@2x.png';

export const BTCST: Token = {
  name: 'BTCST',
  decimals: 17,
  symbol: 'btcst',
  logoURI: BTCSTLogo,
  address: contractConfig.STOKEN
};

export const BTCB: Token = {
  name: 'BTCB',
  decimals: 18,
  symbol: 'btcb',
  logoURI: BTCBLogo,
  address: contractConfig.RTOKEN
};

export const TBTC: Token = {
  name: '𝜏Bitcoin',
  decimals: 9,
  symbol: 'tbtc',
  logoURI: TBTCLogo,
  address: contractConfig.TBTC
};

export const TDOGE: Token = {
  name: '𝜏Doge',
  decimals: 8,
  symbol: 'tdoge',
  logoURI: TDOGELogo,
  address: contractConfig.TDOGE
};

export const DOGE: Token = {
  name: 'Doge',
  decimals: 8,
  symbol: 'doge',
  logoURI: DOGELogo,
  address: contractConfig.DOGE
};

export const TokenList = {
  btcst: BTCST,
  btcb: BTCB,
  tbtc: TBTC,
  tdoge: TDOGE,
  doge: DOGE
};

export const BTCBMainFarm: FarmingInfo = {
  address: contractConfig.FARM,
  type: EnTokenMining.DAILY,
  staking: [{ stoken: BTCST, pid: 0 }],
  rtoken: BTCB
};

export const BTCSTMainFarm: FarmingInfo = {
  address: contractConfig.FARM_BTCST,
  type: EnTokenMining.DAILY,
  staking: [{ stoken: BTCST, pid: 0 }],
  rtoken: BTCST
};

export const TBCTChefFarm: FarmingInfo = {
  address: contractConfig.TBTC_CHEF,
  type: EnTokenMining.BLOCK,
  staking: [
    { stoken: BTCST, pid: 0 },
    { stoken: BTCB, pid: 1 },
    { stoken: TBTC, pid: 2 }
  ],
  rtoken: TBTC
};

export const TDOGEChefFarm: FarmingInfo = {
  address: contractConfig.TDOGE_CHEF,
  type: EnTokenMining.BLOCK,
  staking: [
    { stoken: BTCST, pid: 0 },
    { stoken: TDOGE, pid: 3 },
    { stoken: TBTC, pid: 1 },
    { stoken: DOGE, pid: 2 }
  ],
  rtoken: TBTC
};

export const FarmingPidMap = {
  [BTCB.symbol]: 1,
  [BTCST.symbol]: 0,
  [TBTC.symbol]: 2
};

export const FarmingTDogePidMap = {
  [TDOGE.symbol]: 3,
  [BTCST.symbol]: 0,
  [TBTC.symbol]: 1,
  [DOGE.symbol]: 2
};

export const FarmingInfoMap = {
  [BTCB.symbol]: BTCBMainFarm,
  [BTCST.symbol]: BTCSTMainFarm,
  [TBTC.symbol]: TBCTChefFarm,
  [TDOGE.symbol]: TDOGEChefFarm
};
