import { ReactNode } from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';

import AirdropsIcon from './navIcon/AirdropsIcon';
import MineIcon from './navIcon/MineIcon';
import StatsIcon from './navIcon/StatsIcon';
import SupportIcon from './navIcon/SupportIcon';
import CommunityIcon from './navIcon/CommunityIcon';
import TutorialsIcon from './navIcon/TutorialsIcon';
import MediumIcon from './navIcon/MediumIcon';
import TelegramIcon from '@material-ui/icons/Telegram';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import MarketMakeIcon from './navIcon/MarketMakeIcon';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

export interface INavItem {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: INavItem[];
  title: string;
  onClick?: any;
}

export const menus: INavItem[] = [
  {
    title: 'menu.airdrops',
    icon: AirdropsIcon,
    href: '/airdrop'
  },
  {
    title: 'menu.mine',
    icon: MineIcon,
    href: '/mine'
  },
  // {
  //   title: 'Stats',
  //   icon: StatsIcon,
  //   href: '/stats'
  // },
  // {
  //   title: 'apitest',
  //   icon: StatsIcon,
  //   href: '/apitest'
  // },
  {
    title: 'menu.tsage',
    icon: MarketMakeIcon,
    href: '/nft'
  },
  {
    title: 'menu.stats',
    icon: StatsIcon,
    href: '/dashboard'
  },
  {
    title: 'menu.swap',
    icon: SwapHorizIcon,
    href: 'https://swap.btcst.finance'
  }
];

export const footerMenus: INavItem[] = [
  {
    title: 'menu.vote',
    icon: ThumbUpIcon,
    href: 'https://vote.btcst.finance/'
  },
  {
    title: 'menu.tutorials',
    icon: TutorialsIcon,
    href:
      'https://btcst.medium.com/%CF%84bitcoin-launch-guide-1-faqs-b33bd63f8a09'
  },
  {
    title: 'menu.support',
    icon: SupportIcon,
    href: 'mailto: support@btcst.zendesk.com'
  },
  {
    title: 'menu.communities',
    icon: CommunityIcon,
    items: [
      {
        title: 'menu.twitter',
        icon: TwitterIcon,
        href: 'https://twitter.com/btcst2020'
      },
      {
        title: 'menu.medium',
        icon: MediumIcon,
        href: 'http://btcst.medium.com/'
      },
      {
        title: 'menu.telegram',
        icon: TelegramIcon,
        href: 'https://t.me/BTCST_Community_EN'
      }
    ]
  },
  {
    title: 'menu.v1app',
    icon: PresentToAllIcon,
    href: 'https://v1.btcst.finance'
  }
];
