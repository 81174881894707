import React from 'react';

const AirdropsIcon = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>Airdrops</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-132.000000, -240.000000)" stroke="currentColor" strokeWidth="2">
          <g transform="translate(132.286246, 240.000000)">
            <path d="M18.95241,4.00435646 L16.2735688,18.7199345 C16.1746558,19.2632895 15.6539947,19.623581 15.1106397,19.524668 C14.9860235,19.5019827 14.8668516,19.4558292 14.7594624,19.3886624 L1.90519343,11.3489212 C1.43695225,11.0560584 1.29478003,10.4390616 1.58764288,9.97082047 C1.69557797,9.79824941 1.85360473,9.66272495 2.04061052,9.5823534 L17.5737207,2.90651649 C18.081128,2.68844244 18.6692468,2.92299337 18.8873209,3.43040065 C18.965004,3.61115119 18.9876451,3.81080049 18.95241,4.00435646 Z" id="路径"></path>
            <path d="M18.2862464,3.17050535 L6.28624639,14.627523 L6.28624639,18.6431933 C6.28624639,19.1954781 6.73396164,19.6431933 7.28624639,19.6431933 C7.56251715,19.6431933 7.82647867,19.5288991 8.0155117,19.3274244 L10.5595307,16.6159648" id="路径-4"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AirdropsIcon;
