import { Token } from './token';
import { BigNumber } from 'ethers';

export enum RewardCycle {
  Day = 'day',
  Block = 'block',
};

export interface BTCSTMineItem {
  stakeToken: Token;
  targetToken: Token;
  apy: number;
  rewardCycle: RewardCycle;
  endBlock?: number;
}

export interface LPMineItem extends BTCSTMineItem {}

export interface WrappedMineItem extends BTCSTMineItem {}

export interface RewardItem {
  token: Token;
  rewardAmount: BigNumber;
}

export type PoolType = 'live' | 'finished';
