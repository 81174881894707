import React from 'react';

const MarketMakeIcon = (props) => {
  return (
    <svg width="67px" height="70px" viewBox="0 0 67 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>MarketMakeIcon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-260.000000, -569.000000)" stroke="currentColor" strokeWidth="4">
          <g transform="translate(260.000000, 569.000000)">
            <path d="M2.98529412,14.3147059 L2.98529412,60.0147059 C2.98529412,63.8806991 6.11930087,67.0147059 9.98529412,67.0147059 L57.0147059,67.0147059 C60.8806991,67.0147059 64.0147059,63.8806991 64.0147059,60.0147059 L64.0147059,14.3147059 L2.98529412,14.3147059 Z" id="矩形"></path>
            <path d="M2.98529412,14.5 L64.0147059,14.5 L64.0147059,9 C64.0147059,5.13400675 60.8806991,2 57.0147059,2 L9.98529412,2 C6.11930087,2 2.98529412,5.13400675 2.98529412,9 L2.98529412,14.5 Z" id="矩形备份-9"></path>
            <path d="M20.0147059,24.7 C20.0147059,32.1477223 26.0522777,38.1852941 33.5,38.1852941 L33.5,38.1852941 C40.9477223,38.1852941 46.9852941,32.1477223 46.9852941,24.7" id="路径"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MarketMakeIcon;
