/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
  makeStyles,
  ButtonGroup,
  Button,
  ListItem,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mobileNavOpenAtom, videoModalOpenAtom } from 'src/atoms';
import { INavItem, menus, footerMenus } from 'src/constants/navbar';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
interface NavBarProps {
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: INavItem[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }: {
  acc: any[];
  pathname: string;
  item: INavItem;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={i18n.t(item.title)}
        onClick={item.onClick}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={i18n.t(item.title)}
        onClick={item.onClick}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
}));

const NavBar: FC<NavBarProps> = () => {
  const classes = useStyles();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [isMobileNavOpen, setMobileNavOpen] = useRecoilState(mobileNavOpenAtom);
  const setVideoModalOpen = useSetRecoilState(videoModalOpenAtom);
  const onMobileClose = useCallback(() => {
    setMobileNavOpen(false);
  }, [setMobileNavOpen]);

  // const newFooterMenus = useMemo(() => {
  //   return [
  //     {
  //       title: 'menu.video',
  //       icon: PlayCircleOutlineIcon,
  //       onClick: () => {
  //         if (mobileDevice) {
  //           window.open('https://player.vimeo.com/video/518083707');
  //         } else {
  //           setVideoModalOpen(true);
  //         }
  //       }
  //     },
  //     ...footerMenus
  //   ]
  // }, [setVideoModalOpen, mobileDevice])

  useEffect(() => {
    if (isMobileNavOpen) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        p={2}
        display="flex"
        justifyContent="center"
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>
      {
        // title
      }
      <Divider />
      <Box p={2} flexGrow={1}>
        <List>
          {
            renderNavItems({
              items: menus,
              pathname: location.pathname
            })
          }
        </List>
      </Box>
      <Divider />
      <Box p={2}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={() => i18n.changeLanguage('en')} color={i18n.language === 'en' ? 'primary' : 'default'}>EN</Button>
          <Button onClick={() => i18n.changeLanguage('zh')} color={i18n.language === 'zh' ? 'primary' : 'default'}>中文</Button>
        </ButtonGroup>
      </Box>
      <Divider />
      <Box p={2}>
        <List component="nav">
          {
            renderNavItems({
              items: footerMenus,
              pathname: location.pathname
            })
          }
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={isMobileNavOpen}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
};

export default NavBar;
