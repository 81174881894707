import React, { FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
  Dialog,
  Typography,
  Box,
  Divider,
  Link,
  useTheme,
  useMediaQuery,
  Grid
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { DialogTitle, DialogContent } from 'src/components/Dialog';
import { accountModalStatusAtom } from 'src/atoms';
import { connectorLocalStorageKey } from 'src/constants';
import { toast } from 'react-toastify';
import { toastConfig } from 'src/config';
import { useTranslation } from 'react-i18next';

interface AccountModalProps {}

const AccountModal: FC<AccountModalProps> = () => {
  const { deactivate, account = '' } = useWeb3React();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [accountModalStatus, setAccountModalStatus] = useRecoilState(
    accountModalStatusAtom
  );

  const handleClose = useCallback(() => {
    setAccountModalStatus(false);
  }, [setAccountModalStatus]);

  const handleLogout = useCallback(() => {
    deactivate();
    window.localStorage.removeItem(connectorLocalStorageKey);
    handleClose();
  }, [deactivate, handleClose]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={accountModalStatus}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('global.yourWallet')}
      </DialogTitle>
      <DialogContent dividers>
        <Box p={2}>
          <Typography variant="body1">
            {mobileDevice
              ? `${account.slice(0, 12)}.....${account.slice(-12)}`
              : account}
          </Typography>
          <Divider />
          <Box mt={2} mb={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Link
                  variant="body2"
                  href={`https://bscscan.com/address/${account}`}
                  target="_blank"
                  style={{ marginRight: 12 }}
                >
                  {t('gloal.viewOnBscscan')}
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link
                  variant="body2"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (navigator.clipboard) {
                      navigator.clipboard.writeText(account);
                      toast.success(
                        `${t('global.copy.success')}!`,
                        toastConfig
                      );
                    }
                  }}
                >
                  {t('gloal.copyYourAddress')}
                  <FileCopyOutlinedIcon
                    style={{
                      fontSize: 20,
                      marginRight: 12,
                      verticalAlign: 'middle'
                    }}
                    color="primary"
                  />
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Button variant="contained" onClick={handleLogout}>
            {t('global.logout')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AccountModal;
