import mock from 'src/utils/mock';
import { RewardCycle } from 'src/types/mine';
import type { BTCSTMineItem, RewardItem } from 'src/types/mine';
import type { Token } from 'src/types/token';
import { BTCST, BTCB } from 'src/constants/token';

const T_BTCST: Token = {
  name: 'BTCST',
  decimals: 18,
  symbol: 'btcst',
  logoURI: 'tokens/BTCST.png',
  address: ''
};

const T_BTC: Token = {
  name: 'BTC',
  decimals: 18,
  symbol: 'btc',
  logoURI: 'tokens/BTCB.png',
  address: ''
}

const B_BTC: Token = {
  name: 'BTCB',
  decimals: 18,
  symbol: 'btcb',
  logoURI: 'tokens/BTCB.png',
  address: ''
}

const B_ETH: Token = {
  name: 'BETH',
  decimals: 18,
  symbol: 'beth',
  logoURI: 'tokens/ETH.png',
  address: ''
}

const W_BTC: Token = {
  name: '𝜏Bitcoin',
  decimals: 18,
  symbol: 'w_btc',
  logoURI: 'tokens/BTCB.png',
  address: ''
}

const W_ETHER: Token = {
  name: '𝜏Ether',
  decimals: 18,
  symbol: 'w_ether',
  logoURI: 'tokens/ETH.png',
  address: ''
}

const LP_BTC: Token = {
  name: '𝜏BTC/USD-LP',
  decimals: 18,
  symbol: 'lp_btc',
  logoURI: '',
  address: ''
}

const LP_ETH: Token = {
  name: '𝜏ETH/USD-LP',
  decimals: 18,
  symbol: 'lp_ether',
  logoURI: '',
  address: ''
}

mock.onGet('/api/mine/btcst/list').reply(() => {
  const list: BTCSTMineItem[] = [
    { 
      stakeToken: BTCST,
      targetToken: BTCB,
      apy: 0.8,
      rewardCycle: RewardCycle.Day
    },
    { 
      stakeToken: BTCST,
      targetToken: BTCST,
      apy: 0.5,
      rewardCycle: RewardCycle.Day
    },
  ];

  return [200, {
    list
  }]
});


mock.onGet('/api/mine/lp/list').reply(() => {
  const list: BTCSTMineItem[] = [
    { 
      stakeToken: LP_BTC,
      targetToken: W_BTC,
      apy: 0.8,
      rewardCycle: RewardCycle.Block
    },
    { 
      stakeToken: LP_ETH,
      targetToken: W_ETHER,
      apy: 0.5,
      rewardCycle: RewardCycle.Block
    },
  ];

  return [200, {
    list
  }]
});

mock.onGet('/api/mine/wrapped/list').reply(() => {
  const list: BTCSTMineItem[] = [
    { 
      stakeToken: B_BTC,
      targetToken: W_BTC,
      apy: 0.8,
      rewardCycle: RewardCycle.Block
    },
    { 
      stakeToken: B_ETH,
      targetToken: W_ETHER,
      apy: 0.5,
      rewardCycle: RewardCycle.Block
    },
  ];

  return [200, {
    list
  }]
});
