import React, { FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
  Dialog,
  Typography,
  Box,
  Divider,
  Link,
  makeStyles
} from '@material-ui/core';

import { DialogTitle, DialogContent } from 'src/components/Dialog';
import { videoModalOpenAtom } from 'src/atoms';
import Vimeo from '@u-wave/react-vimeo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {
    width: 672,
  }
}));

interface VideModalProps {}

const VideModal: FC<VideModalProps> = () => {
  const [videoModalOpen, setVideoModalOpen] = useRecoilState(videoModalOpenAtom);
  const { t } = useTranslation(); 
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setVideoModalOpen(false);
  }, [setVideoModalOpen]);

  return (
    <Dialog
      maxWidth={false}
      onClose={handleClose}
      aria-labelledby="vimeo-dialog-title"
      open={videoModalOpen}
    >
      <DialogTitle id="vimeo-dialog-title" onClose={handleClose}>
        {t('menu.video')}
      </DialogTitle>
      <DialogContent className={classes.dialog} dividers>
        <Box>
          <Vimeo
            video="518083707"
            autoplay
            paused={!videoModalOpen}
            width={640}
            height={360}
            muted
          />
          {/* <iframe
            id="video-iframe"
            src="https://player.vimeo.com/video/518083707?api=1"
            width="640"
            height="360"
            frameborder="1"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideModal;
