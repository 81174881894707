import React, { FC, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  withStyles,
  IconButton,
  createStyles,
  Theme,
  WithStyles,
  List,
  ListItem
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { toast } from 'react-toastify';

import { walletModalStatusAtom } from 'src/atoms';
import { walletConnectors } from './connectors';
import WalletItem from './WalletItem';
import { connectorsByName, ConnectorNames } from 'src/connectors';
import { setupNetwork } from 'src/utils/wallet';
import { chainName, toastConfig } from 'src/config';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

interface WalletModalProps {}

const WalletModal: FC<WalletModalProps> = () => {
  // const { connect, status, account, error } = useWallet();

  const { account, activate } = useWeb3React();
  const [walletModalStatus, setWalletModalStatus] = useRecoilState(
    walletModalStatusAtom
  );
  const handleClose = useCallback(() => {
    setWalletModalStatus(false);
  }, [setWalletModalStatus]);


  const handleConnect = (connectorId: ConnectorNames) => {
    const connector = connectorsByName[connectorId];
    if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(connector);
          }
        } else {
          toast.error(`Please use the ${chainName} network`, toastConfig);
        }
      });
    } else {
      console.error("Can't find connector", 'The connector config is wrong');
    }
  };

  useEffect(() => {
    // console.log('status', status);
    console.log('account', account);
    // console.log('error', error);
  }, [account]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="wallet-dialog-title"
      open={walletModalStatus}
    >
      <DialogTitle id="wallet-dialog-title" onClose={handleClose}>
        Connect to Wallet
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {walletConnectors.map(connector => (
            <ListItem key={connector.title}>
              <WalletItem connect={handleConnect} connector={connector} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default WalletModal;
