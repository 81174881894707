import { ToastOptions } from 'react-toastify';

export const chainID = process.env.REACT_APP_CHAIN_ID;
export const chainNetworkUrl = process.env.REACT_APP_NETWORK_URL;
export const chainName = process.env.REACT_APP_NETWORK_NAME;
export const contractConfig = {
  RTOKEN: process.env.REACT_APP_CONTRACT_RTOKEN,
  STOKEN: process.env.REACT_APP_CONTRACT_STOKEN,
  FARM: process.env.REACT_APP_CONTRACT_FARM,
  FARM_BTCST: process.env.REACT_APP_CONTRACT_FARM_BTCST,
  REBASE_ORCH: process.env.REACT_APP_CONTRACT_REBASE_ORCH,
  TBTC: process.env.REACT_APP_CONTRACT_TBTC,
  DOGE: process.env.REACT_APP_CONTRACT_DOGE,
  TDOGE: process.env.REACT_APP_CONTRACT_TDOGE,
  TBTC_ORACLE: process.env.REACT_APP_CONTRACT_TBTC_ORACLE,
  TBTC_POLICY: process.env.REACT_APP_CONTRACT_TBTC_POLICY,
  TDOGE_POLICY: process.env.REACT_APP_CONTRACT_TDOGE_POLICY,
  TBTC_CHEF: process.env.REACT_APP_CONTRACT_TBTC_CHEF,
  TDOGE_CHEF: process.env.REACT_APP_CONTRACT_TDOGE_CHEF,
  MASTER_COLLECTOR: process.env.REACT_APP_CONTRACT_MASTER_COLLECTOR
};

export const toastConfig: ToastOptions = {
  position: 'top-right',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined
};
