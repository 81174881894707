import { atom } from 'recoil';
import { TokenPriceList } from 'src/service';
import { BigNumber } from 'ethers';

export const mobileNavOpenAtom = atom<boolean>({
  key: 'mobileNavOpen',
  default: false
});

export const videoModalOpenAtom = atom<boolean>({
  key: 'videoModalOpen',
  default: false
});

export const tokenPriceListAtom = atom<TokenPriceList>({
  key: 'tokenPriceList',
  default: {}
});

export const tokenPriceLoadingAtom = atom<boolean>({
  key: 'tokenPriceLoading',
  default: true
});

export const tDogeLatestExpandAmountAtom = atom<string>({
  key: 'tDogeLatestExpandAmount',
  default: '0'
});

export const tBTCLatestExpandAmountAtom = atom<string>({
  key: 'tBTCLatestExpandAmount',
  default: '0'
});
