import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/airdrop" />
  },
  {
    path: '/airdrop',
    exact: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/airdrop'))
  },
  {
    path: '/mine',
    exact: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/mine'))
  },
  {
    path: '/mine/stake',
    exact: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/stake'))
  },
  // {
  //   path: '/stats',
  //   exact: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import('src/views/stats'))
  // },
  // {
  //   path: '/apitest',
  //   exact: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import('src/views/apitest'))
  // },
  {
    path: '/dashboard',
    exact: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/tauboard'))
  },
  {
    path: '/nft',
    exact: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/nft'))
  },
  {
    path: '*',
    component: () => <Redirect to="/404" />,
  }
];

export default routes;
