import React, { FC, useCallback } from 'react';
import {
  Button,
  makeStyles,
} from '@material-ui/core';
import { WalletConnector } from './connectors';
import { walletModalStatusAtom } from 'src/atoms';
import { useSetRecoilState } from 'recoil';
import { connectorLocalStorageKey } from 'src/constants';


const useStyles = makeStyles(() => ({
  root: {
  },
  button: {
    width: '100%'
  },
}));

interface WalletItemProps { 
  connect: any;
  connector: WalletConnector;
}

const WalletItem: FC<WalletItemProps> = ({ 
  connect,
  connector
}) => {
  const classes = useStyles();
  const setWalletModalStatus = useSetRecoilState(walletModalStatusAtom);
  const { connectorId, icon: Icon } = connector;
  const handleClick = useCallback(() => {
    connect(connectorId);
    window.localStorage.setItem(connectorLocalStorageKey, connectorId);
    setWalletModalStatus(false);
  }, [connect, connectorId, setWalletModalStatus]);
  

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="default"
      onClick={handleClick}
      endIcon={<Icon style={{ fontSize: 34 }} />}
    >
      { 
        connector.title
      }
    </Button>
  );
}

export default WalletItem;
