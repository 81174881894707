import { FC } from 'react';
import MetamaskIcon from './icons/MetaMask';
import BinanceChainIcon from './icons/BinanceChain';
import MathWalletIcon from './icons/MathWallet';
import TokenPocketIcon from './icons/TokenPocket';
import TrustWalletIcon from './icons/TrustWallet';
import WalletConnectIcon from './icons/WalletConnect';
import SafePalWalletIcon from './icons/SafePalWallet';
import { SvgIconProps } from '@material-ui/core';

// @ts-ignore

export interface WalletConnector {
  title: string;
  icon: FC<SvgIconProps>;
  connectorId: 'injected' | 'bsc' | 'walletconnect';
}

export const walletConnectors: WalletConnector[] = [
  {
    title: 'Metamask',
    icon: MetamaskIcon,
    connectorId: 'injected'
  },
  {
    title: 'Binance Chain Wallet',
    icon: BinanceChainIcon,
    connectorId: 'bsc'
  },

  {
    title: 'TrustWallet',
    icon: TrustWalletIcon,
    connectorId: 'injected'
  },
  {
    title: 'MathWallet',
    icon: MathWalletIcon,
    connectorId: 'injected'
  },
  {
    title: 'TokenPocket',
    icon: TokenPocketIcon,
    connectorId: 'injected'
  },
  {
    title: 'WalletConnect',
    icon: WalletConnectIcon,
    connectorId: 'walletconnect'
  },
  {
    title: 'SafePal Wallet',
    icon: SafePalWalletIcon,
    connectorId: 'injected'
  }
];
