import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createHashHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
// import { WalletProvider } from './contexts/WalletProvider';
import { RecoilRoot } from 'recoil';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'
import { NetworkContextName } from 'src/constants/index'
import Web3ReactManager from './components/Web3ReactManager';
import { ToastContainer } from 'react-toastify';

import i18n from 'src/i18n';

import 'react-toastify/dist/ReactToastify.css';
import VideoModal from './components/VideoModal';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createHashHistory();

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000
  return library
}

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Web3ReactManager>
              <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider
                      dense
                      maxSnack={3}
                    >
                      {/* <WalletProvider> */}
                        <Router history={history}>
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          <ToastContainer />
                          <VideoModal />
                          {renderRoutes(routes)}
                        </Router>
                      {/* </WalletProvider> */}
                    </SnackbarProvider>
                  </MuiPickersUtilsProvider>
                </StylesProvider>
              </ThemeProvider>
            </Web3ReactManager>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </RecoilRoot>
    </I18nextProvider>
  );
};

export default App;
